import React, { useState, useEffect } from "react";
import CustomBigCard from "../custom/CustomBigCard";
import ReservationsMMIP from "./ReservationsMMIP";
import { get_my_maison_reservations } from "../../api";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { selectEndDate, selectStartDate } from "../../reducers/filterSlice";

const MetaMMIP = ({ period }) => {
    const [reservations, setReservations] = useState([]);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);

    const formatReservationsData = (reservations) => {
        console.log("Formatage des réservations :", reservations);
        return reservations.map((reservation) => ({
            reference: reservation.reference,
            date_arrivee: dayjs(reservation.checkInDate).format("DD/MM/YYYY"), // Modification du format des dates
            date_depart: dayjs(reservation.checkOutDate).format("DD/MM/YYYY"), // Modification du format des dates
            montant_resa: reservation.totalAmount || 0,
            channelCode: reservation.channelCode, // On garde le channelCode pour filtrer
        }));
    };

    useEffect(() => {
        console.log("Récupération des réservations My Maison In Paris...");
        console.log("Date de début :", startDate);
        console.log("Date de fin :", endDate);

        get_my_maison_reservations(
            dayjs(startDate).format("YYYY-MM-DD"),
            dayjs(endDate).format("YYYY-MM-DD")
        )
            .then((response) => {
                console.log("Réponse de l'API reçue :", response);
                if (response && response.length > 0) {
                    const formattedReservations =
                        formatReservationsData(response);
                    console.log(
                        "Réservations formatées :",
                        formattedReservations
                    );

                    const metaReservations = formattedReservations.filter(
                        (res) => res.channelCode === "Metasearch_googlehpa"
                    );

                    setReservations(metaReservations);

                    const totalAmount = metaReservations.reduce(
                        (sum, r) => sum + r.montant_resa,
                        0
                    );
                    const averageBasket = totalAmount / metaReservations.length;

                    setData({
                        totalReservations: metaReservations.length,
                        totalAmount: totalAmount,
                        averageBasket: averageBasket,
                    });
                    console.log("Données après calculs :", {
                        totalReservations: metaReservations.length,
                        totalAmount: totalAmount,
                        averageBasket: averageBasket,
                    });
                } else {
                    console.warn(
                        "Aucune réservation trouvée pour la période donnée."
                    );
                    setData({
                        totalReservations: 0,
                        totalAmount: 0,
                        averageBasket: 0,
                    });
                    setReservations([]);
                }
            })
            .catch((error) => {
                console.error("Erreur de l'API :", error);
                setData({
                    totalReservations: 0,
                    totalAmount: 0,
                    averageBasket: 0,
                });
                setReservations([]);
            })
            .finally(() => {
                console.log("Fin de la récupération des réservations.");
                setIsLoading(false);
            });
    }, [startDate, endDate]);

    if (isLoading) {
        console.log("Chargement des données...");
        return (
            <div className="loader-container">
                <CircularProgress size={30} />
            </div>
        );
    }

    console.log("Rendu du composant avec les données :", {
        reservations,
        data,
    });
    return (
        <div className="ads-container">
            <div className="ads-row">
                <CustomBigCard
                    data_first={{
                        label: "Montant des réservations",
                        data_0: data?.totalAmount || 0,
                        tooltip:
                            "Les montants des réservations sont la somme des réservations obtenues sur la période avec meta.",
                        attribut: "€",
                    }}
                    data_second={{
                        label: "Réservations",
                        data_0: data?.totalReservations || 0,
                    }}
                    data_third={{
                        label: "Panier moyen",
                        data_0: data?.averageBasket || 0,
                        attribut: "€",
                    }}
                    period={period}
                />
            </div>
            <ReservationsMMIP data={reservations} period={period} />
        </div>
    );
};

export default MetaMMIP;
