import React, { useState } from "react";
import CustomBigCard from "../custom/CustomBigCard";
import Reservations from "./Reservations";
import { get_meta_bookings } from "../../api";
import dayjs from "dayjs";
import { globalBookings } from "../admin/functionGlobal";
import { useSelector } from "react-redux";
import { selectEndDate, selectStartDate } from "../../reducers/filterSlice";

const Meta = ({ meta, period, property, bookings }) => {
    const [reservations, setReservations] = useState(bookings || []);
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const [data, setData] = useState(meta);

    React.useEffect(() => {
        if (property?.id && !bookings) {
            console.log("Appel de get_meta_bookings avec:", {
                propertyId: property.id,
                startDate: dayjs(startDate).format("YYYY-MM-DD"),
                endDate: dayjs(endDate).format("YYYY-MM-DD"),
            });

            get_meta_bookings(
                property.id,
                dayjs(startDate).format("YYYY-MM-DD"),
                dayjs(endDate).format("YYYY-MM-DD")
            )
                .then((response) => {
                    console.log("Réponse reçue dans Meta.jsx :", response);

                    const processedReservations = globalBookings(response);
                    console.log(
                        "Réservations traitées :",
                        processedReservations
                    );

                    setReservations(processedReservations);
                })
                .catch((error) => {
                    console.error(
                        "Erreur lors de l'appel API dans Meta.jsx:",
                        error
                    );
                });
        }
    }, [property?.id, startDate, endDate, bookings]);

    return data !== undefined && data !== null ? (
        <div className="ads-container">
            <div className="ads-row">
                <CustomBigCard
                    data_first={data.data[0]}
                    data_second={data.data[1]}
                    data_third={data.data[5]}
                    period={period}
                    tooltip={
                        data.data[0].tooltip ? data.data[0].tooltip : false
                    }
                />
                <CustomBigCard
                    data_first={data.data[4]}
                    data_second={data.data[2]}
                    data_third={data.data[3]}
                    period={period}
                    tooltip={
                        data.data[0].tooltip ? data.data[0].tooltip : false
                    }
                    isInvert
                />
            </div>
            <Reservations data={reservations} period={period} />
        </div>
    ) : (
        <></>
    );
};

export default Meta;
