import React from "react";
import CustomCard from "../custom/CustomCard";
import CustomBigCard from "../custom/CustomBigCard";
import { Link } from "react-router-dom";

const Ads = ({ data, period }) => {
    return (
        <>
            {data ? (
                <div className={`ads-container ${data.sample ? "sample" : ""}`}>
                    <div className="ads-row">
                        <CustomBigCard
                            data_first={data.data[0]}
                            data_second={data.data[1]}
                            data_third={data.data[5]}
                            period={period}
                            tooltip={data.data[0].tooltip || false}
                        />
                        <CustomBigCard
                            data_first={data.data[4]}
                            data_second={data.data[2]}
                            data_third={data.data[3]}
                            period={period}
                            tooltip={data.data[0].tooltip || false}
                            isInvert
                        />
                    </div>
                    <div className="ads-row small">
                        {data.data.slice(6).map((item, index) => (
                            <CustomCard
                                data={item}
                                key={index}
                                no_period={true}
                                tooltip={item.tooltip || false}
                            />
                        ))}
                    </div>
                </div>
            ) : (
                <div className="analytics-row is_big">
                    <p>
                        Vous ne disposez pas de compte Google Ads ou nous
                        n'avons pas d'accès administrateur à ce compte. <br />
                        Veuillez contacter notre support à l'adresse{" "}
                        <Link to="mailto:support@altelis.com">
                            support@altelis.com
                        </Link>{" "}
                        si vous souhaitez voir ces informations.
                    </p>
                </div>
            )}
        </>
    );
};

export default Ads;
