import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Navigate,
    Route,
    Routes,
    useNavigate,
    useLocation,
} from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Tippy from "@tippyjs/react";
import axios from "axios";
import { logout } from "../../api";
import { selectUser } from "../../reducers/userSlice";
import Menu from "../menu/Menu";
import CustomSearchInput from "../custom/CustomSearchInput";
import Properties from "./properties/Properties";
import Customers from "./customers/Customers";
import MacroView from "./macro/MacroView";
import CustomPopover from "../custom/CustomPopover";
import "./AdminPanel.scss";

const AdminPanel = () => {
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchData, setSearchData] = useState([]);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const isAdmin = user && user.role === "admin";
    const sectionAffichage = isAdmin
        ? "main-app-right-part admin-role"
        : "main-app-right-part";

    const location = useLocation();

    const [headerInfo, setHeaderInfo] = useState({
        titre: "Bienvenue 👋",
        codeReservation: "",
    });

    useEffect(() => {
        const recuperationID = () => {
            const segmentationURL = location.pathname.split("/");
            const URLProperty =
                segmentationURL.findIndex(
                    (segment) => segment === "properties"
                ) + 1;
            return URLProperty < segmentationURL.length
                ? parseInt(segmentationURL[URLProperty], 10)
                : null;
        };

        const formatcodeReservation = (property) => {
            console.log(property.meta_hotel_code);
            let codeReservation = "";
            if (property.meta_hotel_code) {
                codeReservation = `D-Edge - (${property.meta_hotel_code})`;
            } else if (property.meta_hotel_code_mister_booking) {
                codeReservation = `Mister Booking - (${property.meta_hotel_code_mister_booking})`;
            } else if (property.meta_hotel_code_reservit) {
                codeReservation = `Reservit - (${property.meta_hotel_code_reservit})`;
            }
            return codeReservation;
        };

        const propertyId = recuperationID();
        let titre, codeReservation;

        if (propertyId) {
            const property = user?.properties?.find((p) => p.id === propertyId);
            if (property) {
                codeReservation = formatcodeReservation(property);
                titre = property.name;
            } else {
                titre = "Propriété non trouvée";
                codeReservation = "";
            }
        } else {
            titre = user?.firstname
                ? `Bienvenue, ${user.firstname} 👋`
                : "Bienvenue 👋";
            codeReservation = "";
        }

        setHeaderInfo({ titre, codeReservation });
    }, [location, user?.properties, user?.firstname]);

    const handleDisconnect = useCallback(() => {
        logout().then(() => navigate("/login"));
    }, [navigate]);

    const handleSearchbarSelect = useCallback(
        (property) => {
            navigate("/admin/properties/" + property.id);
        },
        [navigate]
    );

    const togglePopover = useCallback(() => {
        setPopoverVisible(!popoverVisible);
    }, [popoverVisible]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_API_URL}user`,
                    { withCredentials: true }
                );
                setSearchData(response.data.data);
            } catch (err) {
                if (isAdmin) {
                    console.log(
                        "Veuillez contacter un administrateur, si vous avez des erreurs."
                    );
                }
            }
        };

        fetchData();
    }, [dispatch, isAdmin]);

    return (
        <div className="main-app admin-panel">
            <Menu />
            <main className={sectionAffichage}>
                <div className="main-navbar">
                    <div className="navbar-title">
                        <h1>{headerInfo.titre}</h1>
                        {headerInfo.codeReservation && (
                            <span>{headerInfo.codeReservation}</span>
                        )}
                    </div>
                    <div className="navbar-container">
                        <div className="searchbar-container">
                            <CustomSearchInput
                                labelType="user"
                                entity={JSON.parse(
                                    JSON.stringify(user.properties)
                                )}
                                onChange={handleSearchbarSelect}
                                value={"nom"}
                                isFetchable={false}
                            />
                        </div>
                        <Tippy
                            content={
                                <CustomPopover
                                    isVisible={popoverVisible}
                                    onToggle={togglePopover}
                                    onDisconnect={handleDisconnect}
                                />
                            }
                            allowHTML={true}
                            placement={"bottom-end"}
                            offset={[0, 0]}
                            visible={popoverVisible}
                            onClickOutside={togglePopover}
                            interactive={true}
                            appendTo={"parent"}
                        >
                            <div className="user-item" onClick={togglePopover}>
                                {user ? (
                                    <span>
                                        {user.firstname
                                            ? user.firstname
                                            : user.name}
                                    </span>
                                ) : (
                                    <CircularProgress size={20} />
                                )}
                            </div>
                        </Tippy>
                    </div>
                </div>
                <section className="c-section">
                    <Routes>
                        <Route path="properties/*" element={<Properties />} />
                        <Route path="customers/*" element={<Customers />} />
                        <Route
                            path="macro/*"
                            element={<MacroView clients={searchData} />}
                        />
                        <Route
                            path="*"
                            element={<Navigate to="properties" />}
                        />
                    </Routes>
                </section>
            </main>
        </div>
    );
};

export default AdminPanel;
