import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    PDFDownloadLink,
    Image,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import backgroundImageFirst from "../../assets/images/background-image-first.jpg";
import backgroundImageSecond from "../../assets/images/background-image-second.jpg";
import { styles } from "./CustomStylePdf";

const formatCodeReservation = (property) => {
    let codeReservation = "";
    if (property.meta_hotel_code) {
        codeReservation = `D-Edge (${property.meta_hotel_code})`;
    } else if (property.meta_hotel_code_mister_booking) {
        codeReservation = `Mister Booking (${property.meta_hotel_code_mister_booking})`;
    } else if (property.meta_hotel_code_reservit) {
        codeReservation = `Reservit (${property.meta_hotel_code_reservit})`;
    }
    return codeReservation;
};

const CustomExport = ({ startDate, endDate, property, data }) => {
    const codeReservation = formatCodeReservation(property);
    const texteBleu = { color: "#3b61e1" };
    const texteNoirOpacite = { color: "rgba(0, 0, 0, 0.5)" };
    const anneeActuelle = new Date().getFullYear();

    const montantTotal = data?.all?.[0]?.data_0 ?? 0;
    const montantOrganiques = data?.all?.[1]?.data_0 ?? 0;
    const montantAds = data?.all?.[2]?.data_0 ?? 0;
    const montantMetas = data?.all?.[3]?.data_0 ?? 0;

    const pourcentageOrganiques = montantTotal
        ? (montantOrganiques / montantTotal) * 100
        : 0;
    const pourcentageAds = montantTotal ? (montantAds / montantTotal) * 100 : 0;
    const pourcentageMetas = montantTotal
        ? (montantMetas / montantTotal) * 100
        : 0;

    const paysTableauFiltre = [
        "France",
        "Switzerland",
        "Poland",
        "United States",
        "Italy",
    ];

    const formatDonneesTemps = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return [
            h > 9 ? h : "0" + h,
            m > 9 ? m : "0" + m,
            s > 9 ? s : "0" + s,
        ].join(":");
    };

    const recuperationDonneesTableau = data?.analytics?.[1]?.rows
        ? data.analytics[1].rows
              .filter((row) => paysTableauFiltre.includes(row.dimensions[0]))
              .sort(
                  (a, b) =>
                      paysTableauFiltre.indexOf(a.dimensions[0]) -
                      paysTableauFiltre.indexOf(b.dimensions[0])
              )
              .slice(0, 5)
              .map((row) => ({
                  country: row.dimensions[0],
                  totalUsers: row.metrics[0] ?? "Indisponible",
                  pageViews: row.metrics[1] ?? "Indisponible",
                  averageSessionDuration: formatDonneesTemps(
                      row.metrics[3] ?? 0
                  ),
                  bounceRate: row.metrics[2]
                      ? (row.metrics[2] * 100).toFixed(1)
                      : "Indisponible",
              }))
        : [];

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View>
                    <Image
                        src={backgroundImageFirst}
                        style={styles.background}
                    />
                </View>
                <Text
                    style={{
                        top: "545px",
                        ...styles.texteSection,
                        ...styles.nomHotel,
                    }}
                >
                    {property.name}{" "}
                    <Text style={texteNoirOpacite}>- {codeReservation}</Text>
                </Text>
                <Text
                    style={{
                        top: "565px",
                        ...styles.texteSection,
                        ...styles.titrePDF,
                    }}
                >
                    Rapport de <Text style={texteBleu}>statistiques</Text>
                </Text>
                <Text
                    style={{
                        top: "625px",
                        ...styles.texteSection,
                        ...styles.textePeriode,
                    }}
                >
                    Période du <Text style={texteBleu}>{startDate}</Text> au{" "}
                    <Text style={texteBleu}>{endDate}</Text>
                </Text>
                <View style={styles.copyrightWrapper}>
                    <Text style={styles.copyrightTexte}>
                        {anneeActuelle} Altelis. Tous droits réservés.
                    </Text>
                </View>
            </Page>
            <Page size="A4" style={styles.pageContent}>
                <View>
                    <Image
                        src={backgroundImageSecond}
                        style={styles.background}
                    />
                </View>
                <View style={styles.contentWrapper}>
                    {montantTotal > 0 && (
                        <>
                            <View style={styles.headerWrapper}>
                                <Text style={styles.headerTitle}>
                                    Les{" "}
                                    <Text style={texteBleu}>
                                        réservations directes
                                    </Text>
                                </Text>
                                <Text style={styles.headerSubtitle}>
                                    Généré par votre site internet
                                </Text>
                            </View>
                            <View style={styles.cardWrapper}>
                                <View style={styles.cardHeaderWrapper}>
                                    <Text style={styles.cardDataHeader}>
                                        {typeof montantTotal === "number"
                                            ? `${montantTotal.toFixed(0)} €`
                                            : "Indisponible"}
                                    </Text>
                                    <View style={styles.cardHeaderTitleWrapper}>
                                        <Text style={styles.cardTitre}>
                                            Montant total des réservations
                                        </Text>
                                        <Text style={styles.cardSubtitle}>
                                            Correspond à l'ensemble des
                                            réservations passées grâce à votre
                                            site internet
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.line}></View>
                                <View style={styles.cardContentWrapper}>
                                    <View style={styles.cardContentHeader}>
                                        <View style={styles.cardDataWrapper}>
                                            <Text style={styles.cardDataBleu}>
                                                {typeof montantOrganiques ===
                                                "number"
                                                    ? `${montantOrganiques.toFixed(
                                                          0
                                                      )} €`
                                                    : "Indisponible"}
                                            </Text>
                                            <Text
                                                style={{
                                                    ...styles.cardDataPourcent,
                                                    ...styles.cardDataPourcentBleu,
                                                }}
                                            >
                                                {pourcentageOrganiques.toFixed(
                                                    0
                                                )}
                                                %
                                            </Text>
                                        </View>
                                        <Text style={styles.cardTitre}>
                                            Réservations Organiques
                                        </Text>
                                    </View>
                                    <View style={styles.cardContentHeader}>
                                        <View style={styles.cardDataWrapper}>
                                            <Text style={styles.cardDataBleu}>
                                                {typeof montantAds === "number"
                                                    ? `${montantAds.toFixed(
                                                          0
                                                      )} €`
                                                    : "Indisponible"}
                                            </Text>
                                            <Text
                                                style={{
                                                    ...styles.cardDataPourcent,
                                                    ...styles.cardDataPourcentJaune,
                                                }}
                                            >
                                                {pourcentageAds.toFixed(0)}%
                                            </Text>
                                        </View>
                                        <Text style={styles.cardTitre}>
                                            Réservations Google Ads
                                        </Text>
                                    </View>
                                    <View style={styles.cardContentHeader}>
                                        <View style={styles.cardDataWrapper}>
                                            <Text style={styles.cardDataBleu}>
                                                {typeof montantMetas ===
                                                "number"
                                                    ? `${montantMetas.toFixed(
                                                          0
                                                      )} €`
                                                    : "Indisponible"}
                                            </Text>
                                            <Text
                                                style={{
                                                    ...styles.cardDataPourcent,
                                                    ...styles.cardDataPourcentRose,
                                                }}
                                            >
                                                {pourcentageMetas.toFixed(0)}%
                                            </Text>
                                        </View>
                                        <Text style={styles.cardTitre}>
                                            Réservations Métas
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </>
                    )}
                    {data?.analytics?.length > 0 && (
                        <>
                            <View style={styles.headerWrapper}>
                                <Text style={styles.headerSubtitle}>
                                    Vos statistiques
                                </Text>
                                <View style={styles.headerTitleWrapper}>
                                    <Image
                                        src={require("../../assets/images/google-analytics-logo.png")}
                                        style={styles.iconeTitre}
                                    />
                                    <Text style={styles.headerTitle}>
                                        Google{" "}
                                        <Text style={texteBleu}>Analytics</Text>
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.cardGrid4Wrapper}>
                                <View style={styles.cardGrid4}>
                                    <Text style={styles.cardDataAltelis}>
                                        {data.analytics[0]?.totals?.[0] ??
                                            "Indisponible"}
                                    </Text>
                                    <View style={styles.cardGrid4Content}>
                                        <Text style={styles.cardTitre}>
                                            Utilisateurs
                                        </Text>
                                        <Text style={styles.cardSubtitle}>
                                            Utilisateurs qui ont initié au moins
                                            une session dans la plage de dates
                                            sélectionnée.
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.cardGrid4}>
                                    <Text style={styles.cardDataAltelis}>
                                        {data.analytics[1]?.totals?.[1] ??
                                            "Indisponible"}
                                    </Text>
                                    <View style={styles.cardGrid4Content}>
                                        <Text style={styles.cardTitre}>
                                            Pages vues
                                        </Text>
                                        <Text style={styles.cardSubtitle}>
                                            Il s'agit du nombre total de pages
                                            consultées. Les visites répétées
                                            d'un internaute sur une même page
                                            sont prises en compte.
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.cardGrid4}>
                                    <Text style={styles.cardDataAltelis}>
                                        {data.analytics[1]?.totals?.[3]
                                            ? new Date(
                                                  data.analytics[1].totals[3] *
                                                      1000
                                              )
                                                  .toISOString()
                                                  .substr(11, 8)
                                            : "Indisponible"}
                                    </Text>
                                    <View style={styles.cardGrid4Content}>
                                        <Text style={styles.cardTitre}>
                                            Session moyenne
                                        </Text>
                                        <Text style={styles.cardSubtitle}>
                                            Le temps moyen passé par un
                                            utilisateur au cours d'une session.
                                            Les visites répétées d'un internaute
                                            sur une même page sont prises en
                                            compte.
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.cardGrid4}>
                                    <Text style={styles.cardDataAltelis}>
                                        {data.analytics[1]?.totals?.[2] !==
                                        undefined
                                            ? `${(
                                                  data.analytics[1].totals[2] *
                                                  100
                                              ).toFixed(2)} %`
                                            : "Indisponible"}
                                    </Text>
                                    <View style={styles.cardGrid4Content}>
                                        <Text style={styles.cardTitre}>
                                            Taux de rebond
                                        </Text>
                                        <Text style={styles.cardSubtitle}>
                                            Pourcentage de sessions avec
                                            consultation d'une seule page de
                                            votre site au cours desquelles
                                            aucune interaction n'a été
                                            enregistrée.
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </>
                    )}
                    {data?.analytics?.length > 0 && (
                        <>
                            <View style={styles.headerWrapper}>
                                <Text style={styles.headerSubtitle}>
                                    Vos statistiques Google Analytics
                                </Text>
                                <View style={styles.headerTitleWrapper}>
                                    <Image
                                        src={require("../../assets/images/globe--blue.png")}
                                        style={styles.iconeTitre}
                                    />
                                    <Text style={styles.headerTitle}>
                                        En fonction de la{" "}
                                        <Text style={texteBleu}>
                                            localisation
                                        </Text>
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.table}>
                                <View style={styles.tableRowHeader}>
                                    <View
                                        style={[
                                            styles.tableauHeaderWrapper,
                                            { flexBasis: "20%" },
                                            { justifyContent: "flex-start" },
                                        ]}
                                    >
                                        <Image
                                            src={require("../../assets/images/pays.png")}
                                            style={styles.iconeTableau}
                                        />
                                        <Text style={styles.tableCellHeader}>
                                            Pays
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.tableauHeaderWrapper,
                                            { flexBasis: "20%" },
                                        ]}
                                    >
                                        <Image
                                            src={require("../../assets/images/utilisateurs.png")}
                                            style={styles.iconeTableau}
                                        />
                                        <Text style={styles.tableCellHeader}>
                                            Utilisateurs
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.tableauHeaderWrapper,
                                            { flexBasis: "20%" },
                                        ]}
                                    >
                                        <Image
                                            src={require("../../assets/images/pages-vues.png")}
                                            style={styles.iconeTableau}
                                        />
                                        <Text style={styles.tableCellHeader}>
                                            Pages vues
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.tableauHeaderWrapper,
                                            { flexBasis: "20%" },
                                        ]}
                                    >
                                        <Image
                                            src={require("../../assets/images/session-moyenne.png")}
                                            style={styles.iconeTableau}
                                        />
                                        <Text style={styles.tableCellHeader}>
                                            Session moyenne
                                        </Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.tableauHeaderWrapper,
                                            { flexBasis: "20%" },
                                        ]}
                                    >
                                        <Image
                                            src={require("../../assets/images/taux-de-rebond.png")}
                                            style={styles.iconeTableau}
                                        />
                                        <Text style={styles.tableCellHeader}>
                                            Taux de rebond
                                        </Text>
                                    </View>
                                </View>
                                {recuperationDonneesTableau.map(
                                    (row, index) => (
                                        <View
                                            key={index}
                                            style={styles.tableRow}
                                        >
                                            <Text
                                                style={[
                                                    styles.tableCellPays,
                                                    { flexBasis: "20%" },
                                                ]}
                                            >
                                                {row.country}
                                            </Text>
                                            <Text
                                                style={{
                                                    ...styles.tableCell,
                                                    ...styles.tableCellAltelis,
                                                    flexBasis: "20%",
                                                }}
                                            >
                                                {row.totalUsers}
                                            </Text>
                                            <Text
                                                style={[
                                                    styles.tableCell,
                                                    { flexBasis: "20%" },
                                                ]}
                                            >
                                                {row.pageViews}
                                            </Text>
                                            <Text
                                                style={[
                                                    styles.tableCell,
                                                    { flexBasis: "20%" },
                                                ]}
                                            >
                                                {row.averageSessionDuration}
                                            </Text>
                                            <Text
                                                style={{
                                                    ...styles.tableCell,
                                                    ...styles.tableCellAltelis,
                                                    flexBasis: "20%",
                                                }}
                                            >
                                                {row.bounceRate}%
                                            </Text>
                                        </View>
                                    )
                                )}
                            </View>
                        </>
                    )}
                </View>
                <View style={styles.copyrightWrapper}>
                    <Text style={styles.copyrightTexte}>
                        {anneeActuelle} Altelis. Tous droits réservés.
                    </Text>
                </View>
            </Page>
            <Page size="A4" style={styles.pageContent}>
                <View>
                    <Image
                        src={backgroundImageSecond}
                        style={styles.background}
                    />
                </View>
                <View style={styles.contentWrapper}>
                    <View style={styles.headerWrapper}>
                        <View style={styles.headerTitleWrapper}>
                            <Image
                                src={require("../../assets/images/google-adwords-logo.png")}
                                style={styles.iconeTitre}
                            />
                            <Text style={styles.headerTitle}>
                                Google <Text style={texteBleu}>Ads</Text>
                            </Text>
                        </View>
                    </View>
                    <View
                        style={{ ...styles.twoWrapper, ...styles.marginBottom }}
                    >
                        <View style={styles.twoContentHeader}>
                            <Text style={styles.cardDataHeader}>
                                {data?.ads?.data?.[0]?.data_0 !== undefined
                                    ? `${data.ads.data[0].data_0.toFixed(0)} €`
                                    : "Indisponible"}
                            </Text>
                            <View style={styles.cardHeaderTitleWrapper}>
                                <Text style={styles.cardTitre}>
                                    Montant des réservations
                                </Text>
                                <Text style={styles.cardSubtitle}>
                                    Somme des réservations obtenues sur la
                                    période avec Ads
                                </Text>
                            </View>
                            <View style={styles.line}></View>
                            <View style={styles.twoWrapper}>
                                <View style={styles.twoSubContent}>
                                    <Text style={styles.cardDataAltelis}>
                                        {data?.ads?.data?.[1]?.data_0 !==
                                        undefined
                                            ? `${data.ads.data[1].data_0.toFixed(
                                                  0
                                              )} €`
                                            : "Indisponible"}
                                    </Text>
                                    <Text style={styles.cardTitre}>
                                        Réservations
                                    </Text>
                                </View>
                                <View style={styles.twoSubContent}>
                                    <Text style={styles.cardDataAltelis}>
                                        {data?.ads?.data?.[5]?.data_0 !==
                                        undefined
                                            ? `${data.ads.data[5].data_0.toFixed(
                                                  0
                                              )} €`
                                            : "Indisponible"}
                                    </Text>
                                    <Text style={styles.cardTitre}>
                                        Panier moyen
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.twoContentHeader}>
                            <Text style={styles.cardDataHeader}>
                                {data?.ads?.data?.[4]?.data_0 !== undefined
                                    ? `${data.ads.data[4].data_0.toFixed(0)} €`
                                    : "Indisponible"}
                            </Text>
                            <View style={styles.cardHeaderTitleWrapper}>
                                <Text style={styles.cardTitre}>de coût</Text>
                                <Text style={styles.cardSubtitle}>
                                    Montant dépensé pour les campagnes Google
                                    Ads
                                </Text>
                            </View>
                            <View style={styles.line}></View>
                            <View style={styles.twoWrapper}>
                                <View style={styles.twoSubContent}>
                                    <Text style={styles.cardDataAltelis}>
                                        {data?.ads?.data?.[2]?.data_0 !==
                                        undefined
                                            ? `${parseFloat(
                                                  data.ads.data[2].data_0
                                              ).toFixed(2)}%`
                                            : "Indisponible"}
                                    </Text>
                                    <Text style={styles.cardTitreSmall}>
                                        Equiv. commission
                                    </Text>
                                </View>
                                <View style={styles.twoSubContent}>
                                    <Text style={styles.cardDataAltelis}>
                                        {data?.ads?.data?.[3]?.data_0 !==
                                        undefined
                                            ? `${parseFloat(
                                                  data.ads.data[3].data_0
                                              ).toFixed(2)}`
                                            : "Indisponible"}
                                    </Text>
                                    <Text style={styles.cardTitre}>ROI</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View
                        style={{
                            ...styles.cardContentWrapper,
                            ...styles.marginBottom,
                        }}
                    >
                        <View style={styles.cardContent}>
                            <Text style={styles.cardDataAltelis}>
                                {data?.ads?.data?.[6]?.data_0 !== undefined
                                    ? `${data.ads.data[6].data_0}`
                                    : "Indisponible"}
                            </Text>
                            <View style={styles.carContentTexte}>
                                <Text style={styles.cardTitre}>Appels</Text>
                                <Text style={styles.cardTooltip}>
                                    Les annonces Appel incitent à appeler votre
                                    établissement via des appareils aptes aux
                                    appels.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.cardContent}>
                            <Text style={styles.cardDataAltelis}>
                                {data?.ads?.data?.[7]?.data_0 !== undefined
                                    ? `${data.ads.data[7].data_0}`
                                    : "Indisponible"}
                            </Text>
                            <View style={styles.carContentTexte}>
                                <Text style={styles.cardTitre}>
                                    Impressions
                                </Text>
                                <Text style={styles.cardTooltip}>
                                    Les impressions vous permettent de
                                    déterminer combien de fois votre annonce a
                                    été vue.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.cardContent}>
                            <Text style={styles.cardDataAltelis}>
                                {data?.ads?.data?.[8]?.data_0 !== undefined
                                    ? `${data.ads.data[8].data_0}`
                                    : "Indisponible"}
                            </Text>
                            <View style={styles.carContentTexte}>
                                <Text style={styles.cardTitre}>Clics</Text>
                                <Text style={styles.cardTooltip}>
                                    Il s'agit du nombre de fois où les
                                    internautes ont cliqué sur votre annonce.
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ ...styles.cardContentWrapper }}>
                        <View style={styles.cardContent}>
                            <Text style={styles.cardDataAltelis}>
                                {data?.ads?.data?.[9]?.data_0 !== undefined
                                    ? `${parseFloat(
                                          data.ads.data[9].data_0
                                      ).toFixed(2)}%`
                                    : "Indisponible"}
                            </Text>
                            <View style={styles.carContentTexte}>
                                <Text style={styles.cardTitre}>
                                    Taux de clics (CTR)
                                </Text>
                                <Text style={styles.cardTooltip}>
                                    Détermine la fréquence à laquelle les
                                    utilisateurs cliquent sur votre annonce
                                    lorsqu'ils la voient.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.cardContent}>
                            <Text style={styles.cardDataAltelis}>
                                {data?.ads?.data?.[10]?.data_0 !== undefined &&
                                typeof data.ads.data[10].data_0 === "number"
                                    ? `${data.ads.data[10].data_0.toFixed(2)}`
                                    : "Indisponible"}{" "}
                                €
                            </Text>
                            <View style={styles.carContentTexte}>
                                <Text style={styles.cardTitre}>
                                    Coût par clic moyen
                                </Text>
                                <Text style={styles.cardTooltip}>
                                    Correspond au montant que vous avez payé
                                    pour votre annonce, divisé par le nombre
                                    total de clics qu'elle a générés.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.cardContent}>
                            <Text style={styles.cardDataAltelis}>
                                {data?.ads?.data?.[11]?.data_0 !== undefined
                                    ? `${parseFloat(
                                          data.ads.data[11].data_0
                                      ).toFixed(2)}%`
                                    : "Indisponible"}
                            </Text>
                            <View style={styles.carContentTexte}>
                                <Text style={styles.cardTitre}>
                                    Taux de conversion
                                </Text>
                                <Text style={styles.cardTooltip}>
                                    Correspond à la fréquence moyenne à laquelle
                                    une interaction avec une annonce génère une
                                    conversion.
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.headerWrapper}>
                        <View style={styles.headerTitleWrapper}>
                            <Image
                                src={require("../../assets/images/meta.png")}
                                style={styles.iconeTitreMetas}
                            />
                            <Text style={styles.headerTitle}>
                                <Text style={texteBleu}>Métas</Text>
                            </Text>
                        </View>
                    </View>
                    <View style={styles.twoWrapper}>
                        <View style={styles.twoContentHeader}>
                            <Text style={styles.cardDataHeader}>
                                {data?.meta?.data?.[0]?.data_0 !== undefined
                                    ? `${parseFloat(
                                          data.meta.data[0].data_0
                                      ).toFixed(0)} €`
                                    : "Indisponible"}
                            </Text>
                            <View style={styles.cardHeaderTitleWrapper}>
                                <Text style={styles.cardTitre}>
                                    Montant des réservations
                                </Text>
                                <View style={styles.cardBreakTexte}>
                                    <Text style={styles.cardSubtitle}>
                                        Somme des réservations obtenues sur la
                                        période
                                    </Text>
                                    <Text style={styles.cardSubtitle}>
                                        avec Métas
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.line}></View>
                            <View style={styles.twoWrapper}>
                                <View style={styles.twoSubContent}>
                                    <Text style={styles.cardDataAltelis}>
                                        {data?.meta?.data?.[1]?.data_0 !==
                                        undefined
                                            ? `${parseFloat(
                                                  data.meta.data[1].data_0
                                              )}`
                                            : "Indisponible"}
                                    </Text>
                                    <Text style={styles.cardTitre}>
                                        Réservations
                                    </Text>
                                </View>
                                <View style={styles.twoSubContent}>
                                    <Text style={styles.cardDataAltelis}>
                                        {data?.meta?.data?.[5]?.data_0 !==
                                        undefined
                                            ? `${parseFloat(
                                                  data.meta.data[5].data_0
                                              ).toFixed(0)} €`
                                            : "Indisponible"}
                                    </Text>
                                    <Text style={styles.cardTitre}>
                                        Panier moyen
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.twoContentHeader}>
                            <Text style={styles.cardDataHeader}>
                                {data?.meta?.data?.[4]?.data_0 !== undefined
                                    ? `${data.meta.data[4].data_0.toFixed(0)} €`
                                    : "Indisponible"}
                            </Text>
                            <View style={styles.cardHeaderTitleWrapper}>
                                <Text style={styles.cardTitre}>de coût</Text>
                                <Text style={styles.cardSubtitle}>
                                    Montant dépensé pour les campagnes Google
                                    Métas
                                </Text>
                            </View>
                            <View style={styles.line}></View>
                            <View style={styles.twoWrapper}>
                                <View style={styles.twoSubContent}>
                                    <Text style={styles.cardDataAltelis}>
                                        {data?.meta?.data?.[2]?.data_0 !==
                                        undefined
                                            ? `${parseFloat(
                                                  data.meta.data[2].data_0
                                              ).toFixed(2)}%`
                                            : "Indisponible"}
                                    </Text>
                                    <Text style={styles.cardTitreSmall}>
                                        Equiv. commission
                                    </Text>
                                </View>
                                <View style={styles.twoSubContent}>
                                    <Text style={styles.cardDataAltelis}>
                                        {data?.meta?.data?.[3]?.data_0 !==
                                        undefined
                                            ? `${parseFloat(
                                                  data.meta.data[3].data_0
                                              ).toFixed(2)}`
                                            : "Indisponible"}
                                    </Text>
                                    <Text style={styles.cardTitre}>ROI</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.copyrightWrapper}>
                    <Text style={styles.copyrightTexte}>
                        {anneeActuelle} Altelis. Tous droits réservés.
                    </Text>
                </View>
            </Page>
        </Document>
    );
};

const CustomExportPDF = ({ dateRanges, property, data }) => {
    let startDate = "N/A",
        endDate = "N/A";
    if (dateRanges && dateRanges.length > 0) {
        startDate = dayjs(dateRanges[0].start_date).format("DD MMMM YYYY");
        endDate = dayjs(dateRanges[0].end_date).format("DD MMMM YYYY");
    }

    const fileName = `Rapport_${property.name}_${startDate}_${endDate}.pdf`;

    return (
        <PDFDownloadLink
            document={
                <CustomExport
                    startDate={startDate}
                    endDate={endDate}
                    property={property}
                    data={data}
                />
            }
            fileName={fileName}
        >
            <button
                type="submit"
                id="bouton-export"
                title="Exporter les données en PDF"
            >
                <span className="bouton-text">Export PDF</span>
                <svg
                    className="bouton-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                >
                    <path d="M248,128a87.34,87.34,0,0,1-17.6,52.81,8,8,0,1,1-12.8-9.62A71.34,71.34,0,0,0,232,128a72,72,0,0,0-144,0,8,8,0,0,1-16,0,88,88,0,0,1,3.29-23.88C74.2,104,73.1,104,72,104a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,1,1,81.29,88.68,88,88,0,0,1,248,128Zm-69.66,42.34L160,188.69V128a8,8,0,0,0-16,0v60.69l-18.34-18.35a8,8,0,0,0-11.32,11.32l32,32a8,8,0,0,0,11.32,0l32-32a8,8,0,0,0-11.32-11.32Z"></path>
                </svg>
            </button>
        </PDFDownloadLink>
    );
};

export default CustomExportPDF;
